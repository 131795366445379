<script>
import VideoShowNewView from './VideoShowNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'VideoShowEditView',
  extends: VideoShowNewView,
  methods: {
    getVideoShow () {
      this.$store.dispatch('videoShow/fetchOne', this.$route.params.id)
        .then(() => {
          this.videoShow = this.$store.getters['videoShow/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      if (this.videoShow.imageInfo) {
        this.videoShow.image = this.videoShow.imageInfo.id
      }
      this.$store.dispatch('videoShow/update', this.videoShow)
        .then(() => {
          this.videoShow = this.$store.getters['videoShow/detail']
          if (this.$store.getters['videoShow/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['videoShow/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getVideoShow()
  }
}
</script>
